






// https://en.wikipedia.org/wiki/OpenSimplex_noise
// const ctv2jf = require("../CanvasTemplatev2_JacobFoster.js");


var SimplexNoise = require('simplex-noise'),
    noise = new SimplexNoise(Math.random),
    value2d = noise.noise2D(100, 200);


/*

function draw(e) {
  const width = 200;
  

  // var ctx = c.getContext("2d");
  // https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/getContext
  var c = canvas; 
  var ctx c.getContext("2d");

	let xCount = 35;
	let yCount = 80;
	let iXCount = 1 / (xCount - 1);
	let iYCount = 1 / (yCount - 1);
	let time = e * 0.001;
	let timeStep = 0.01;
	let grad = ctx.createLinearGradient(-width, 0, width, height);
	let t = time % 1;
	let tSide = floor(time % 2) === 0;
	let hueA = tSide ? 340 : 210;
	let hueB = !tSide ? 340 : 210;
	let colorA = hsl(hueA, 100, 50);
	let colorB = hsl(hueB, 100, 50);
	grad.addColorStop(map(t, 0, 1, THIRD, ZERO), colorA);
	grad.addColorStop(map(t, 0, 1, TWO_THIRDS, THIRD), colorB);
	grad.addColorStop(map(t, 0, 1, ONE, TWO_THIRDS), colorA);
	ctx.globalAlpha = map(cos(time), -1, 1, 0.15, 0.3);
	background(grad);
	ctx.globalAlpha = 1;
	beginPath();
	for(let j = 0; j < yCount; j++) {
		let tj = j * iYCount;
		let c = cos(tj * TAU + time) * 0.1;
		for(let i = 0; i < xCount; i++) {
			let t = i * iXCount;
			let n = noise.noise3D(t, time, c);
			let y = n * height_half;
			let x = t * (width + 20) - width_half - 10;
			(i ? lineTo : moveTo)(x, y);
		}
		time += timeStep;
	}
	compositeOperation(compOper.lighter);
	ctx.filter = 'blur(10px)';
	stroke(grad, 5);
	ctx.filter = 'blur(5px)';
	stroke(hsl(0, 0, 100, 0.8), 2);
}
*/

/*
// coded message
// https://codepen.io/theodore-q/pen/mJqVZo
var toString = "coded message"

// var toString = $('.code').html()
var OGarray = toString.split('');
var genNum = 0
var counter = 0
var cutOffAt = 55
var obfuscated = 1

function getRandom(len) {
  return new Array(len + 1).join('2');
}

function Gen() {
  var length = toString.length;
  // console.log(length)
  genNum = getRandom(length);
  // console.log(genNum)

  // $('.letters').html(genNum);
  // console.log(genNum)
}

function parser(NUarray) {
  $.each(NUarray, function(index, value) {
    if (value == 0) {
      NUarray[index] = OGarray[index]
    } else if (value == OGarray[index]) {
      NUarray[index] = OGarray[index]
    } else if (isNaN(value)) {
      NUarray[index] = OGarray[index]
    } else {
      change = Math.round(Math.random() * 9);
      NUarray[index] = change
    }
  });
  if (NUarray.toString() == OGarray.toString()) {
    obfuscated = 0
    window.clearInterval(interval)
  }
  if (counter == 50) {
    obfuscated = 0
    window.clearInterval(interval)
    NUarray = OGarray

  }
  counter += 1
  return NUarray.join("")

}

function Genletters(stringToChange) {
  var string = stringToChange.toString().split('')
  return genNum = parser(string)
}

function runThis() {
  Genletters(genNum)
  // console.log(genNum)
  // $('.code').html(genNum)
}
Gen();
var interval = setInterval(function() {
  runThis();
}, 100);
*/


// https://gridsome.org https://www.gatsbyjs.org/
// RxJS v6+
import { timer } from 'rxjs';

import { Component, Vue } from 'vue-property-decorator';


// create vue-particles. 
import VueParticles from 'vue-particles';
Vue.use(VueParticles);

// text effects
import { VueTypedJs } from 'vue-typed-js'; 
Vue.use(VueTypedJs);

// 🦨 TODO: vuejs-logger broken in present typescript. 
// import VueLogger from 'vuejs-logger';
// Vue.use(VueLogger);



@Component({
  name: "HelloElasticVentures",
  data: () => {
    return {
      'evText':'ELASTIC.VENTURES'
    }
  },
  components: {
    VueTypedJs,
  //  VueLeetish
  },

})
export default class HelloElasticVentures extends Vue {

// background effects:
// https://codepen.io/collection/ABNwxq/

msg = 123

mounted() {



  async function firstAsync() {
    let promise = new Promise((res, rej) => {
        setTimeout(() => res("Now it's done!"), 1000)
    });
    // wait until the promise returns us a value
    let result = await promise; 
    //  alert(result);    // outputs 
    let noise = new SimplexNoise();
    }

firstAsync();

}

}


